import './styles.scss'
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";




const Contact = () => {
    return (
        <footer className='box-contact' id="contact">
            <h2>Contatos</h2>
            <p>
                Estou sempre aberto a novas oportunidades e colaborações! Pronto para discutir projetos ou trocar ideias. Não hesite em entrar em contato.
            </p>
            <ul>
                <li>
                    <a href="mailto:gabrielfelipe0722@gmail.com" target='_blank'>
                        <HiOutlineMail />
                    </a>
                </li>
                <li>
                    <a href='https://www.linkedin.com/in/gabrielfelipeee/' target='_blank'>
                        <FaLinkedinIn />
                    </a>
                </li>
                <li>
                    <a href='https://github.com/gabrielfelipeee' target='_blank'>
                        <FaGithub />
                    </a>
                </li>
            </ul>
            <a className="download" href='/curriculum-GabrielFelipe.pdf' download target='_blank'>
                Baixar Currículo
            </a>

            <div className="credits">
                Desenvolvido por Gabriel Felipe
            </div>
        </footer>
    )
}
export default Contact;
