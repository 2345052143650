import './styles.scss';
import { useState } from 'react';
import { Link } from 'react-scroll';
import { IoMenu, IoCloseOutline } from "react-icons/io5";
import { FiMoon } from "react-icons/fi";
import { RxSun } from "react-icons/rx";
import logo from '../../assets/logo.png';


import MenuLink from './MenuLink';


const Menu = (props) => {
    const [displayMenuHamburger, setDisplayMenuHamburger] = useState(false);
    const iconsTheme = {
        size: 24,
        onClick: props.changeTheme,
    };

    // Fecha as opções de menu quando clica em uma opção, no hamburger
    const closeMenu = () => {
        return window.innerWidth < 778 && setDisplayMenuHamburger(false);
    }
    const menuItems = [
        { page: 'Sobre mim', id: "about-me" },
        { page: 'Projetos', id: "projects" },
        { page: 'Contato', id: "contact" },
    ];

    // toggle do display das opções do menu
    const toggleMenu = () => {
        setDisplayMenuHamburger(!displayMenuHamburger);
    };
    const iconsMenu = {
        size: 30,
        cursor: "pointer",
        onClick: toggleMenu
    };

    return (
        <header className="box-header">
            <nav className="box-menu">
                <Link to="about-me" offset={-64} smooth={true} duration={1000}>
                    <img src={logo} alt="logo" />
                </Link>

                <ul className={displayMenuHamburger ? "open-menu" : ""}>
                    {menuItems.map((item, index) =>
                        <li key={index}>
                            <MenuLink {...item} closeMenu={closeMenu}/>
                        </li>
                    )}
                </ul>

                {props.theme === "dark" ?
                    <RxSun className="icon" {...iconsTheme} /> :
                    <FiMoon className="icon" {...iconsTheme} />
                }

                {displayMenuHamburger === false?
                    <IoMenu className="menu-hamburger icon" {...iconsMenu} /> :
                    <IoCloseOutline className="menu-hamburger icon" {...iconsMenu} />
                }
            </nav>
        </header>
    )
}
export default Menu;
