import './styles/theme.scss';
import { useState } from 'react';

import Menu from './components/Menu';
import AboutMe from './main_components/AboutMe';
import Projects from './main_components/Projects';
import Contact from './main_components/Contact';


function App() {

  const [theme, setTheme] = useState("light");
  const changeTheme = () => {
    setTheme((theme) => theme === "light" ? "dark" : "light");
  }


  return (
    <div className={theme}>
      <Menu changeTheme={changeTheme} theme={theme} />

      <main>
        <AboutMe />
        <Projects />
      </main>
      <Contact />
    </div>
  );
}
export default App;
