import './styles.scss';


const AboutMe = () => {
    return (
        <section className="box-about-me" id="about-me">
            <span>Olá, eu sou</span>
            <h1>Gabriel Felipe</h1>
            <h2>Desenvolvedor Front-End</h2>
            <div className='text'>
                <p>
                    Atualmente estou cursando Análise e Desenvolvimento de Sistemas, e estou em busca da minha primeira oportunidade em desenvolvimento Front-End. Estou ansioso por uma chance de contribuir em projetos que me permitam aplicar e aprimorar minhas habilidades nessa área, enquanto enfrento desafios que impulsionarão meu crescimento profissional.
                </p>
                <p>
                    Minha jornada de aprendizado é contínua, embora eu já esteja familiarizado com tecnologias essenciais para o Front-End, como <span>React</span>, <span>JavaScript</span>, <span>TypeScript</span>, <span>Tailwind CSS</span> e <span>SASS</span>, estou sempre em busca de aprimoramento. Continuamente busco expandir meu conhecimento e habilidades através de cursos complementares.
                </p>
                <p>
                    Estou pronto para aplicar minha paixão pela tecnologia e contribuir ativamente para projetos desafiadores. Meu objetivo é fazer parte de uma equipe dinâmica e comprometida com a inovação, onde posso colaborar para alcançar resultados excepcionais no desenvolvimento de software.
                </p>
            </div>
            <img src='https://github.com/gabrielfelipeee.png' alt='Foto do Gabriel Felipe'></img>
        </section>
    )
}
export default AboutMe;
