import './styles.scss';
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";



const ProjectCard = (props) => {
    return (
        <div className="box-project-card">
            <h3>{props.name}</h3>
            <img src={props.image} alt="Imagem de desmonstração do projeto" className='img'></img>
            <p>{props.description}</p>
            <ul className="technologies">
                {props.technology.map(item => <li key={item}>{item}</li>)}
            </ul>
            <ul className="links">
                <li>
                <a href={props.github} target="_blank">
                <FaGithub />
            </a>
                </li>
                <li>
                <a href={props.link} target="_blank">
                <FaExternalLinkAlt />
            </a>
                </li>
            </ul>
        </div>
    )
}
export default ProjectCard;
