import './styles.scss';

import projects from '../../mocks/projects.json';

import ProjectCard from '../../components/ProjectCard';

const Projects = () => {
    return (
        <section className="box-projects" id="projects">
            <h2>Meus Projetos</h2>
            <ul>
                {projects.map(item =>
                    <li key={item.name}>
                        <ProjectCard
                            name={item.name}
                            image={item.image}
                            description={item.description}
                            technology={item.technologies}
                            github={item.repository}
                            link={item.link}
                        />
                    </li>
                )}
            </ul>
        </section>
    )
}
export default Projects;
