import './styles.scss';
import { useState, useEffect } from 'react';
import { Link, Events, scroller } from 'react-scroll';

const MenuLink = (props) => {
    const [activeLink, setActiveLink] = useState(""); // Estado para acompanhar o link ativo

    // Define a seção como ativa quando clicada e faz a rolagem suave até ela
    const handleSetActive = (to) => {
        setActiveLink(to);
        scroller.scrollTo(to, {
            smooth: true,
            duration: 1000,
            offset: 64
        });
    };

    // Registra o evento de início de rolagem para atualizar o link ativo
    useEffect(() => {
        Events.scrollEvent.register("begin", to => {
            setActiveLink(to);
        });

        return () => {
            Events.scrollEvent.remove("begin");
        };
    }, []);

    // Função para verificar a posição da página e atualizar o link ativo
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
        const targetSection = document.getElementById(props.id);

        if (
            targetSection &&
            targetSection.offsetTop - 100 <= currentScrollPos &&
            targetSection.offsetTop + targetSection.offsetHeight > currentScrollPos
        ) {
            setActiveLink(props.id);
        } else {
            setActiveLink("");
        }
    };

    // Adiciona e remove o listener do evento de scroll ao montar e desmontar o componente
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Link
            to={props.id} // ID da seção para onde o link vai rolar
            offset={64}
            smooth={true} // Habilita a rolagem suave
            duration={1000} // Duração da animação de rolagem suave
            onSetActive={() => handleSetActive(props.id)} // Define a seção como ativa após a rolagem
            onClick={() => props.closeMenu()}
            className={`box-menu-link ${activeLink === props.id ? 'active' : ''}`} // Aplica classes CSS com base na seção ativa
        >
            {props.page}
        </Link>
    );
};

export default MenuLink;
